import React, {useCallback, useEffect, useState} from 'react';
import Layout from "../components/layout/layout";
import Seo from "../components/Seo";
import PageTitle from "../components/contentHeadings/PageTitle";
import ContentMargins from "../components/layout/ContentMargins";
import {Link} from "gatsby";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

const localStorageAvailable = () => {
    // Return false during build process
    if (typeof window === "undefined") return false;

    const test = 'local_storage_test';
    try {
        sessionStorage.setItem(test, test);
        sessionStorage.removeItem(test);
        return true;
    } catch (err) {
        console.error("LocalStorage error");
        return false;
    }
};

const CookiesPage = () => {
    const [consent, setConsent] = useState(false);
    useEffect(() => {
        if (localStorageAvailable()){
            const consentDataString = window.localStorage.getItem('cookieConsent_rev2');
            if (!consentDataString) return;

            const consentDataRaw = JSON.parse(consentDataString);
            const someConsent = ['ad_user_data', 'ad_personalization', 'ad_storage', 'analytics_storage'].some(key => {
                return consentDataRaw.hasOwnProperty(key) && consentDataRaw[key] === true;
            });

            setConsent(someConsent);
        }
    }, []);

    const toggleConsent = useCallback(() => {
        setConsent(prevValue => {
            const newConsent = !prevValue;
            const newStringValue = newConsent ? 'granted' : 'denied';

            if (localStorageAvailable()){
                window.localStorage.setItem('cookieBannerDismissed_rev2', "dismissed");
                window.localStorage.setItem('cookieConsent_rev2', JSON.stringify({
                    ad_user_data: newConsent,
                    ad_personalization: newConsent,
                    ad_storage: newConsent,
                    analytics_storage: newConsent
                }));
            }

            window.gtag('consent', 'update', {
                ad_user_data: newStringValue,
                ad_personalization: newStringValue,
                ad_storage: newStringValue,
                analytics_storage: newStringValue
            });
            window.fbq('consent', newConsent ? 'grant' : 'revoke');

            return newConsent;
        });
    }, []);

    return(
        <Layout>
            <Seo
                title={"Informasjonskapsler"}
                extraTags={<meta name="robots" content="noindex" />}
            />
            <ContentMargins>
                <PageTitle>Informasjonskapsler</PageTitle>
                <p>
                    Om du tillater det ønsker vi å bruke informasjonskapsler til å måle og forbedre markedsføringen vår.
                    Nettsiden vil fungere som normalt også om du ikke godtar bruken. Du kan når som helst gi eller trekke
                    tilbake ditt samtykke ved å trykke på knappen nedenfor.
                </p>
                <FormControlLabel
                    control={<Switch checked={consent} onChange={toggleConsent}/>}
                    label={"Jeg godtar bruk av informasjonskapsler"}
                />
                <br/>
                <br/>
                <p>
                    Om du samtykker til bruk av informasjonskapsler til markedsføring vil vi dele informasjon om hvordan
                    du bruker nettsiden, slik som hvilke sider du besøker og hvor lenge du er på siden, med Google og
                    Facebook. Du kan lese om detaljene rundt dette i vår <Link to={'/personvern'}>personvernerklæring</Link>.
                </p>
            </ContentMargins>
        </Layout>
    );
};

export default CookiesPage;